
//bind and init css and events (onLoan only, no ajax)
function initEventsOnce()
{
    initEventsOnceCommon();
    newsCarousel();
    homepageBanners();
}

//bind and init css and events (onLoan and after ajax)
function initEventsWithReload() {
    initEventsWithReloadCommon();
    hideHoneypot();
    initBuyButtons();
    initInputNumberSpinner();
    enableTooltips();
    initPopup();
    initFlashMessages();
    initRegisterReminder();
    initSelect();
    toggleFilter();
    categoryFilter();
    toggleNumber();
    usedin();
    productnav();
    label();
    headerLogin();
    mainMenu();
    categorySidebar();
    likeSelect();
    fixedHeader();
    customerSection();
    productList();
    incart();
    worldShip();
    productProperties();
    lineProperties();
    detailProperties();
    initSupplierCheck();
    productBoxShowMore();
    showAllCategories();
    searchtable();
    initRecaptcha();
    productbox();
    readMoreToggle();
    initLoadingOverlayForm();
}

function initOnErrorPage()
{
    mainMenu();
    fixedHeader();
    initFlashMessages();
}

//close alerts
var hideFlashMessagesTimeout;
function initFlashMessages() {
    clearTimeout(hideFlashMessagesTimeout);
    $(".flashmessage__close").on("click", function () {
        $(this).closest(".flashmessage").remove();
    });
    hideFlashMessagesTimeout = setTimeout(function () {
        $(".flashmessage").not(".noclose").fadeTo(500, 0).slideUp(500, function () {
            $(this).remove();
        });
    }, 10000);
}

function closeAllFlashMessages()
{
    $(".flashmessage").remove();
}

//BUY
function buy(form, link) {
    $.nette.ajax({
        url: link,
        cache: false,
        type: 'GET',
        data: 'articleId=' + form.find('[name=articleId]').val() + '&articleQuantity=' + form.find('[name=articleQuantity]').val() + '&increaseQuantity=' + form.find('[name=increaseQuantity]').val(),
        success: function (json) {
            if (json.status > 0) {
                if (!window.confirm(json.message)) {
                    return;
                }
            }
            if (json.gtm != null && gtmEnabled && typeof dataLayer !== 'undefined' && (!cookieBlocker || (cookieBlocker && (cookieBlockerGtmMode || window.cookiehub.hasConsented("analytics"))))) {
                dataLayer.push(json.gtm);
            }
            form.submit();
        }
    });
}

/*-------------------------------------enable CSS---------------------------------------------*/
function enableTooltips()
{
    tippy('[data-tippy-content]', {
        allowHTML: true
    });
}

function hideAllTooltips()
{
    tippy.hideAll();
}

function initPopup(){
    $('.open-popup').magnificPopup({
        type:'inline',
        midClick: true,
        callbacks: {
            beforeOpen: function() {  this.wrap.removeAttr('tabindex') }
        }
    });
}


function initInputNumberSpinner()
{
    $('<div class="quantity-nav"><div class="quantity-button quantity-up"><i class="icon-chevron-up"></i></div><div class="quantity-button quantity-down"><i class="icon-chevron-down"></i></div></div>').insertAfter('.quantity input');
    $('.quantity').each(function() {
        var spinner = jQuery(this),
            input = spinner.find('input[type="number"]'),
            btnUp = spinner.find('.quantity-up'),
            btnDown = spinner.find('.quantity-down'),
            min = input.attr('min'),
            max = input.attr('max');

        btnUp.off('click');
        btnDown.off('click');
        btnUp.on("click", function() {
            var oldValue = parseFloat(input.val());
            if (oldValue >= max) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue + 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });

        btnDown.on("click", function() {
            var oldValue = parseFloat(input.val());
            if (oldValue <= min) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue - 1;
            }
            spinner.find("input").val(newVal);
            spinner.find("input").trigger("change");
        });

    });
}

function goTop(speed) {
    if (typeof(speed)==='undefined') speed = 0;
    $('html, body').animate({
        scrollTop: 0
    },speed);
}

function goTopOfElement(target, addOffset, speed) {
    if (typeof(speed)==='undefined') speed = 0;
    if (typeof(addOffset)==='undefined') addOffset = 0;

    var jqueryTarget = $(target);
    if (jqueryTarget.length) {
        $('html, body').stop().animate({
            scrollTop: (jqueryTarget.offset().top - addOffset)
        }, speed);
    }
}

//------------------------------------register reminder------------------------------------//
function registerReminder() {
    if(userLoggedIn) {
        return true;
    }
    var timeLimit = 30;
    var registerReminderCookie = Cookies.get('register-reminder');
    if (registerReminderCookie === undefined) {
        Cookies.set('register-reminder', 0);
    } else if (registerReminderCookie < 0) {
        return true;
    } else if (registerReminderCookie >= timeLimit) {
        Cookies.set('register-reminder', timeLimit);
        $(".site-header__user-reminder").addClass('site-header__user-reminder--active');
        return true;
    } else {
        registerReminderCookie++;
        Cookies.set('register-reminder', registerReminderCookie);
    }

    registerTimeout = setTimeout(function () {
        registerReminder();
    }, 1000);
}

function initRegisterReminder() {
    var $disableButton = $(".site-header__user-reminderclose");
    $disableButton.off('click');
    $disableButton.on('click', function () {
        $(".site-header__user-reminder").removeClass('site-header__user-reminder--active');
        Cookies.set('register-reminder', -1, { expires: 21 });
    });
}

function registerReminderCookieRefresh() {
    if (Cookies.get('register-reminder') !== undefined && Cookies.get('register-reminder') < 0) {
        Cookies.set('register-reminder', Cookies.get('register-reminder'), {expires: 21});
    }
}

function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

//------------------------------------New------------------------------------//
function toggleFilter() {

    $(".site-filter__header-item").off("click");
    $(".site-filter__header-left").off("click");
    $(".site-filter__header-mobile").off("click");

    $( ".site-filter__header-item" ).on( "click", function() {
        toggleFilterItems($(this))
    });

    $(".site-filter__header-left").on ("click",function(){
        $(".site-filter__header-item").toggleClass("site-filter__header-item--show");
        categoryFilterShow($('.site-filter__minimizer'));
    });

    $(".site-filter__header-mobile").on ("click",function(){
        if($(this).parent(".site-filter__header").hasClass("site-filter__header--opened")) {
            toggleFilterItems($('.site-filter__header-item--active'));
        } else {
            $(".site-filter__header-item").addClass("site-filter__header-item--show");
            $(this).parent(".site-filter__header").addClass("site-filter__header--opened");
        }
    });
}

function toggleFilterItems(item)
{
    var number = item.data("number")
    item.siblings().removeClass("site-filter__header-item--active");
    item.addClass("site-filter__header-item--active");
    $(".site-filter__header").removeClass("site-filter__header--opened");

    $(".site-filter__content-item[data-number='" + number +"']").siblings().removeClass("site-filter__content-item--opened");
    $(".site-filter__content-item[data-number='" + number +"']").siblings().addClass("site-filter__content-item--closed");
    $(".site-filter__header-text[data-number='" + number +"']").siblings().removeClass("site-filter__header-text--active");


    $(".site-filter__content-item[data-number='" + number +"']").addClass("site-filter__content-item--opened");
    $(".site-filter__header-text[data-number='" + number +"']").addClass("site-filter__header-text--active");
}

function categoryFilter(){
    $('.site-filter__minimizer').on("click",function(){
        categoryFilterToggle($(this));
    });
}

function categoryFilterToggle(minimizer)
{
    $('.site-filter__content--category').toggleClass("site-filter__content--show");
    minimizer.toggleClass("site-filter__minimizer--opened");
    $(".site-filter__header-left").toggleClass("site-filter__header-left--show");
    $(".site-filter__header-right").toggleClass("site-filter__header-right--show");
}

function categoryFilterShow(minimizer)
{
    $('.site-filter__content--category').addClass("site-filter__content--show");
    minimizer.addClass("site-filter__minimizer--opened");
    $(".site-filter__header-left").addClass("site-filter__header-left--show");
    $(".site-filter__header-right").addClass("site-filter__header-right--show");
}

function toggleNumber(){
    $('.site-filter__content-box  .js-select').on("change" , function(){
        $(this).siblings('.site-filter__content-number').addClass('site-filter__content-number--passed')
    });
}

function initSelect() {
    $(document).ready(function() {
        $('.js-select').select2({
            width: '100%',
            placeholder: function(){
                $(this).data('placeholder');
            }
        });

        $('.js-multiselect').select2({
            width: '100%',
            closeOnSelect: false,
            placeholder: function(){
                $(this).data('placeholder');
            }
        });
        $('.site-skeleton--select, .site-skeleton--select_low').hide();
    });

    $("select.js-multiselect").on('select2:open', function (e) {
        const selectId = $(this).attr('id');

        $(".select2-container--open > .selection").append("<button type='button' class='multiselect-button site-form__line-find' data-select-id='"+selectId+"'><span class='icon-find'></span></button>");
        $(".select2-container--open > .selection").append("<button type='button' class='multiselect-button site-form__line-clear' data-select-id='"+selectId+"'>Vymazat</button>");
        initSelect2CloseButton();
        initSelect2ClearButton();

        /*
        * jquery 3.6.0 fix
        * for safari with timeout
        */
        setTimeout(function(){
            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
                key,
                value,
            ){
                value.focus();
            });
        },100);
    });
}

function initSelect2CloseButton()
{
    $(".multiselect-button.site-form__line-find").on("click", function(){
        $('#'+$(this).data('select-id')).select2('close');
    });
}

function initSelect2ClearButton()
{
    $(".multiselect-button.site-form__line-clear").on("click", function(){
        $('#'+$(this).data('select-id')).val(null).trigger("change");
        $('#'+$(this).data('select-id')).select2('close');
    });
}

function newsCarousel(){
    $('.news-carousel__container').not('.slick-initialized').slick({
        dots: false,
        infinite: false,
        arrows: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
              arrows: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });
}

function homepageBanners(){
    $('.blog-feed__slider').not('.slick-initialized').slick({
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: "progressive",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
              arrows: false,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
}

function usedin(){
    $(".togglelink").on("click",function(){
        $(this).toggleClass("active");
        $(this).siblings("ul").toggleClass("toggle--open")
    });

    $(document).ready(function(){
        $(".usedin__buttons .btn:first-child").toggleClass("usedin__buttons-item--active");
        $(".usedin__content .usedin__main:first-child").addClass("usedin__main--opened");
    });

    $(".usedin__buttons-item").on("click", function(){
        $(this).siblings(".btn").removeClass("usedin__buttons-item--active");
        $(this).addClass("usedin__buttons-item--active");


        var number = $(this).data("number");

        $(".usedin__main[data-number='" + number +"']").addClass("usedin__main--opened");
        $(".usedin__main[data-number='" + number +"']").siblings(".usedin__main").removeClass("usedin__main--opened");
    });
}

function productnav(){
    $(".product-nav__item").on("click",function(){
        $(this).siblings().removeClass("product-nav__item--active");
        $(this).addClass("product-nav__item--active");
    });
}

function label(){
    $(document).ready(function(){
        var $input = $ ('.site-form__line--pack input, .site-form__line--pack textarea');

        $input.focus (function () {
            $ (this).parent().addClass ('js-focused');
        }).change (function () {
            $ (this).parent().addClass ('js-focused');
        }).blur (function () {
            var tmpval = $ (this).val ();
            if (tmpval === '') {
                $ (this).parent().removeClass ('js-focused');
            } else {
                $ (this).parent().addClass ('js-focused');
            }
        });

        $input.each (function () {
            var val = $ (this).val ();
            if (val.length > 0) {
                $ (this).parent().addClass ('js-focused');
            }
    });
});

}

function headerLogin(){
    $(".site-header__user-login").off("click");
    $(".site-header__user-login").on("click",function(e){
        e.preventDefault();
        $(".site-header__user-popup").toggleClass("site-header__user-popup--active")
    });
}

function mainMenu(){
    $(".site-nav__title").on("click",function(){
        $(".site-nav__main").addClass("site-nav__main--active")
    });

    $(".site-nav__main-close").on("click",function(){
        $(".site-nav__main").removeClass("site-nav__main--active")
    });

    jQuery.fn.clickOutside = function(callback){
        var $me = this;
        $(document).mouseup(function(e) {
            if ( !$me.is(e.target) && $me.has(e.target).length === 0 ) {
                callback.apply($me);
            }
        });
    };

    $('.site-nav__main').clickOutside(function(){
        $(this).removeClass('site-nav__main--active');
    });

}

function categorySidebar(){
    $(".category-sidebar__item-switch").off("click");
    $(".category-sidebar__heading").off("click");
    $(".category__controls-filters").off("click");
    $(".category-sidebar__close").off("click");

    $(".category-sidebar__item-switch").on("click",function(){
        $(this).parent(".category-sidebar__item-haschild").siblings(".category-sidebar__submenu").toggleClass("category-sidebar__submenu--show");
        $(this).toggleClass("category-sidebar__item-switch--opened");
    });

    $(".category-sidebar__heading").on("click",function(){
        $(this).toggleClass("category-sidebar__heading--noactive")
        $(this).next(".category-sidebar__content").toggleClass("category-sidebar__content--noactive")
    });

    $(".category__controls-filters").on("click",function(){
        $(".category-sidebar").addClass("category-sidebar--active");
        $("body").addClass("fixed");
        $("#site-header__main").addClass("site-header__main--fixed");
        $("#site-header__top").hide();
    });

    $(".category-sidebar__close").on("click",function(){
        closeCategorySidebar();
    });

    $(".filter-close").on("click",function(){
        closeCategorySidebar();
    });

    /*jQuery.fn.clickOutside = function(callback){
        var $me = this;
        $(document).mouseup(function(e) {
            if ( !$me.is(e.target) && $me.has(e.target).length === 0 ) {
                callback.apply($me);
            }
        });
    };

    $('.category-sidebar').clickOutside(function(){
        $(this).removeClass('category-sidebar--active');
        $("body").removeClass("fixed");
    });*/

}

function closeCategorySidebar()
{
    $(".category-sidebar").removeClass("category-sidebar--active");
    $("body").removeClass("fixed");
    $("#site-header__top").show();
    fixHeader();
}

function likeSelect(){
    $(".like-select").off("click");
    $(".like-select").on("click",function(){
        $(this).find(".like-select__submenu").toggleClass("like-select__submenu--active")
    });
}

function fixedHeader(){
    window.onscroll = function() {fixHeader()};
}

function fixHeader() {
    var header = document.getElementById("site-header__main");
    var sticky = header.offsetTop;
    if (window.pageYOffset > sticky + 2) {
        header.classList.add("site-header__main--fixed");
    } else {
        header.classList.remove("site-header__main--fixed");
    }
}

function showAllCategories(){
    $(".all-categories__item-more").off("click");
    $(".all-categories__item-more").on("click",function(){
        $(this).hide();
        $(".all-categories__item--homepage").toggleClass("all-categories__item--show");
    });
}

function customerSection(){
    $(".get-identity").on("click",function(){
        $(".get-identity__card").toggleClass("get-identity__card--show");
    });

    $(".customer-table td:first-child").on("click",function(){
        $(this).siblings("td").toggleClass("td-open");
        $(this).toggleClass("header-open");
    });

    $(".header-open").on("click",function(){
        $(this).siblings("td").toggleClass("td-open");
        $(this).toggleClass("header-open");
    });

    $('.show-incomplete-items').on('click',function (){

        $('table.customer-table tr.status-complete').hide();
        $('.show-incomplete-items').hide();
        $('.show-all-items').show();
        stipeTableOrder();
    });

    $('.show-all-items').on('click',function (){
        $('.customer-table tr').show();
        $('.show-all-items').hide();
        $('.show-incomplete-items').show();
        stipeTableOrder();
    });

    function stipeTableOrder()
    {
        $("table.customer-table tbody tr.article").removeClass("odd even");
        $("table.customer-table tbody tr.article:visible:odd").addClass("odd");
        $("table.customer-table tbody tr.article:visible:even").addClass("even");
    }

    $('.expand-all-items').on('click',function (){
        $(".customer-table tr.article td:first-child").addClass("header-open");
        $(".customer-table tr.article td:nth-child(n+2)").addClass("td-open");
    });

    $('.pack-all-items').on('click',function (){
        $(".customer-table tr.article td:first-child").removeClass("header-open");
        $(".customer-table tr.article td:nth-child(n+2)").removeClass("td-open");
    });

}

function searchtable(){
    $(".searchtable td:first-child").on("click",function(){
        $(this).siblings("td").toggleClass("td-open");
        $(this).toggleClass("header-open");
    });

    $(".searchtable").on("click",function(){
        $(this).siblings("td").toggleClass("td-open");
        $(this).toggleClass("header-open");
    });
}

function productList(){
    $(".product-smallest__properties-switch").on("click",function(){
        $(this).siblings(".product-smallest__properties-list").toggleClass("product-smallest__properties-list--open");
    });
}

var hideIncartTimeout;
function incart(){
    clearTimeout(hideIncartTimeout);
    $(".js-incartclose").on("click",function(){
        $(".incart").addClass("incart--close");
    });

    hideIncartTimeout = setTimeout(function(){
        // toggle another class
        $('.incart').addClass('incart--close');
    },5000);
}

function worldShip(){
    $(".worldship").hide();
    $(".worldship-control").on("click",function(){
        $(".worldship").toggle();
        $(".worldship-control").toggle();
    });

}

function productProperties(){
    $(".product-item__properties-header").off("click").on("click",function(){
        $(this).siblings(".product-item__properties-content").toggleClass("product-item__properties-content--open");
        $(this).toggleClass("product-item__properties-header--open");
    });
}

function lineProperties(){
    $(".product-line__showall").off("click").on("click",function(){
        $(this).siblings(".product-line__properties").toggleClass("product-line__properties--open");
        $(this).toggleClass("product-line__showall--open");
    });
}

function detailProperties(){
    $(".product-detail__data-showall").off("click").on("click",function(){
        $(this).siblings(".product-detail__data").toggleClass("product-detail__data--open");
        $(this).toggleClass("product-detail__data-showall--open");
    });
}

function readMoreToggle(){
    $(".read-more-text-toggle").off("click").on("click",function(e){
        e.preventDefault();
        var parentDiv = $(this).parents(".read-more-text");
        parentDiv.children(".read-more-text-short").toggle();
        parentDiv.children(".read-more-text-full").toggle();
    });
}

function initSupplierCheck() {
    $('.checkSupplierStock').off('click').on('click',function (e){
        e.preventDefault();
        $('form.checkSupplierStockForm-'+$(this).data('id')).submit();
    });
}

function initRecaptcha() {
    $('<script />', {
        src: 'https://www.google.com/recaptcha/api.js'
    }).insertBefore('script:first');
}

function hideHoneypot()
{
    $(".frm-text-hp-important").closest('.site-form__line').addClass('site-form__line-hp');
}

function productBoxShowMore(){
    $(".product-detail__box-more").on("click", function(){
        $(this).siblings(".product-detail__box-list").find(".product-detail__box-item--invisible").toggleClass("product-detail__box-item--hidden");
        $(this).find(".more").toggle();
        $(this).find(".less").toggle();
        $(this).addClass("product-detail__box-more--open");
    });
}

function initLoadingOverlayForm() {
    $('form.show-loading').on("submit", function(e) {
        e.preventDefault();
        if(Nette.validateForm(this)) {
            $('.ajax-loading').show(0);
            this.submit();
        }
    });
}

function productbox(){
    $(".product-detail__pack-text .more").on("click",function(){
        $(this).siblings().show();
        $(this).hide();
    });
}


//------------------------------------ga-opt-out------------------------------------
function gaOptout() {
    Cookies.set('disable-analytics-tools', true, {expires: 365});
    Cookies.set(gaDisableStr, true, {expires: 365});
    window[gaDisableStr] = true;
}

